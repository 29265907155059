.button {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    padding: 4px;
    border: initial;
    background: initial;
}

.icon {
    display: block;
    filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, .8));
}

