/* Login.css */

.login {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f4f4f4;
}

.login form {
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    width: 100%;
    max-width: 400px;
}

.login input[type="password"] {
    width: calc(100% - 2px); /* Adjust to ensure proper width, accounting for border */
    padding: 0.75rem;
    margin-bottom: 1rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
    box-sizing: border-box; /* Ensures padding and border are included in width */
}

.login button {
    width: 100%;
    padding: 0.75rem;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: #ffffff;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.2s ease;
    box-sizing: border-box; /* Ensures padding and border are included in width */
}

.login button:hover {
    background-color: #0056b3;
}

.login button:focus {
    outline: none;
}