.link {
    --shadow-color: 0deg 0% 63%;
    --shadow-elevation-high: 0.2px 0.2px 0.3px hsl(var(--shadow-color) / 0.34),
    1px 1px 1.6px -0.4px hsl(var(--shadow-color) / 0.34), 1.9px 1.9px 3px -0.7px hsl(var(--shadow-color) / 0.34),
    3.1px 3.1px 4.9px -1.1px hsl(var(--shadow-color) / 0.34), 4.9px 4.9px 7.8px -1.4px hsl(var(--shadow-color) / 0.34),
    7.7px 7.6px 12.2px -1.8px hsl(var(--shadow-color) / 0.34),
    11.6px 11.6px 18.5px -2.1px hsl(var(--shadow-color) / 0.34),
    17.1px 17.1px 27.2px -2.5px hsl(var(--shadow-color) / 0.34);

    transition: box-shadow ease 300ms;
}

.link:hover {
    z-index: 1;
    box-shadow: var(--shadow-elevation-high);
}
